import {React,useState} from "react";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import LeftIcon from "../../assets/images/left-arrow.png";
import RightIcon from "../../assets/images/right-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { getLatestData,getLatestStages } from "../../redux/connectWallet/action";
import { toast } from "react-toastify";
const BulkParticipateModal = (props) => {
    let dispatch = useDispatch();
    const contractData = useSelector((state) => state.contract);
    const leftOrRightMove = async(direction) =>{
        if (contractData?.contractInstance) {
            try {
              props.lodaer(true);
              props.close();
              const side = direction == "left" ? false : true;

              const transaction = await  contractData?.contractInstance.bulkParticipateInGame(side,props.playerIds);
              const compTransaction = await transaction.wait();
              if(compTransaction){
                dispatch(getLatestStages(contractData?.contractInstance));
                dispatch(getLatestData(contractData?.contractInstance));
                // toast.success("Bulk Participate completed successfully")
              }
              else{
                props.lodaer(false);
                toast.error("Error In Transaction")
              }
      
            } catch (error) {
              props.lodaer(false);
              toast.error(error?.error?.data.message ? error?.error?.data?.message : "Error In Transaction");
              console.log("ERROR>>",error)
            }
          }
    }
    return (

        <>
            {props.show === true ? (
                <div className="time-modal">
                    <button
                        type="button"
                        className="close"
                        onClick={() => props.close()}
                    >
                        {/* <span aria-hidden="true">&times;</span> */}
                    </button>
                    <div className="custom-modal-header">Select Tile For Jump </div>
                    <div className="custom-modal-content">
                        <div className="arrow-wrapper d-flex align-items-center justify-content-between">
                            {props.safeSides[props.stage] !== undefined &&
                                props.safeSides[props.stage].safeTile >= 50 ? (
                                <div className="arrow-icon right-icon"></div>
                            ) : (
                                <div
                                    className="arrow-icon left-icon"
                                    onClick={() => leftOrRightMove("left")}
                                >
                                    <img src={LeftIcon} alt="icon left" className="img-fluid" />
                                </div>
                            )}

                            {props.safeSides[props.stage] !== undefined &&
                                props.safeSides[props.stage].safeTile < 50 ? (
                                <div className="arrow-icon right-icon"></div>
                            ) : (
                                <div
                                    className="arrow-icon right-icon"
                                    onClick={() => leftOrRightMove("right")}
                                >
                                    <img src={RightIcon} alt="icon right" className="img-fluid" />
                                </div>
                            )}
                        </div>
                        {/* <button className="btn-connect">Stay</button> */}
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    )
}
export default BulkParticipateModal;
