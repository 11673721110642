import { ethers } from "ethers";
import axios from "axios";

import {
  SUCCESS_CONNECTION,
  FAILING_CONNECTION,
  IS_INSTALLED,
  IS_NOT_INSTALLED,
  ACCOUNTS_CHANGE,
  CONTRACT_INSTANCE,
  STAGES_DATA,
  ALL_USERS_DATA,
  MY_NFT_DATA,
  WALLET_NFTS,
  TOKEN_BALANCE,
  GLOBAL_STAGE,
  LOADER_STATE_TRUE,
  LOADER_STATE_FALSE,
  SET_LATEST_STAGES,
  TRESUARY_BALANCE,
  RELOAD_POPUP_TRUE,
  RELOAD_POPUP_FALSE,
  COUNTDOWN_LOADER_TRUE,
  COUNTDOWN_LOADER_FALSE,
} from "../actionType";
import { ENV } from "../../config/config";
import { toast } from "react-toastify";
// import MetaMaskOnboarding from "@metamask/onboarding";
import contractAbi from "../../utils/contract_abi/contractAbi.json";
import tokenAbi from "../../utils/contract_abi/tokenContractAbi.json";

export const MetamaskConnection = () => async (dispatch) => {
  console.log("CAME HERE");
  if (typeof window.ethereum !== "undefined") {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const network = await provider.getNetwork();

    if (provider) {
      if (network.chainId == process.env.REACT_APP_CHAIN_ID_AVALANCHE) {
        try {
          dispatch({
            type: LOADER_STATE_TRUE,
          });
          await provider.send("eth_requestAccounts", []);
          let signer = provider.getSigner();
          let address = await signer.getAddress();
          let contractInstance = new ethers.Contract(
            process.env.REACT_APP_CONTRACT_ADDRESS,
            contractAbi,
            signer
          );
          const tokenInstance = new ethers.Contract(
            process.env.REACT_APP_WRAPPED_ETHER,
            tokenAbi,
            signer
          );
          let balance = await tokenInstance.balanceOf(address);
          balance = Number(balance / 10 ** 18);
          let dataForCounter = false;
          if (contractInstance) {
            let safetiles = await contractInstance.getAll();
            let capcites = await contractInstance.getCapacity();
            let leadingCapacity = await contractInstance.getCapacityOfLeading();
            console.log("GET ALL>>>>",capcites);
            let allPlayerIds = await contractInstance.allParticipatePlayerID();
            const ownerAddress = await contractInstance.owner();
            let gameStatusGlobalData =
              await contractInstance.gameStatusInitialized(1);
            let globalStage = Number(gameStatusGlobalData.stageNumber);
            let isGameEnd = await contractInstance.isGameEnded();
            let adminStartTime = Number(gameStatusGlobalData.startAt);
            const buyBackCurve = await buyBackCurvess(contractInstance);
            let lastJumpTime = Number(
              await contractInstance.lastUpdateTimeStamp()
            );
            let currentTime = Math.floor(Date.now() / 1000);
            let latestTimestampOfUsers =
              lastJumpTime == 0 || undefined
                ? 0
                : (currentTime - lastJumpTime) / process.env.REACT_APP_TIMESLOT;
            let gameInitializeDay =
              adminStartTime == 0
                ? 0
                : (currentTime - adminStartTime) /
                  process.env.REACT_APP_TIMESLOT;
            const response = await axios.post(
              `${process.env.REACT_APP_SERVER_URL}/v1/series1/get/nft`,
              { walletAddress: address }
            );
            const nftIds = await computePlayerIDPromise(
              response.data.data,
              address
            );
            const gameEndTime = Number(await contractInstance.gameEnded());
            let randomNumber =
              safetiles[globalStage !== 0 ? globalStage - 1 : globalStage]
                ?._hex;
            const tilesNumber =
              globalStage < 1
                ? 1
                : isGameEnd == false
                ? globalStage
                : randomNumber !== undefined
                ? globalStage + 1
                : globalStage;
            const totalReward = await contractInstance.treasuryBalance();
            const gameEnded = await contractInstance.gameEnded();
            dispatch({
              type: WALLET_NFTS,
              payload: response.data.data,
            });
            const countDownStageNumber =
              globalStage > 0 && safetiles[globalStage - 1] !== undefined
                ? globalStage
                : globalStage > 1
                ? globalStage - 1
                : 0;
            console.log(
              "API RESPONSE OF COUNTER DATA>>>>>",
              countDownStageNumber,
              safetiles[countDownStageNumber]
            );

            if (
              globalStage > 0 &&
              safetiles[countDownStageNumber - 1] !== undefined
            ) {
              console.log(
                "API RESPONSE OF COUNTER DATA>>>>> first if condition",
                countDownStageNumber
              );
              const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/v1/counter/data`,
                {
                  walletAddress: address,
                  globalStage: countDownStageNumber,
                  gameNumber: 4,
                }
              );
              if (
                latestTimestampOfUsers > Number(gameEnded) ||
                (isGameEnd === false &&
                  gameInitializeDay !== 0 &&
                  latestTimestampOfUsers >= 1 &&
                  response.data.success == false)
              ) {
                console.log(
                  "API RESPONSE OF COUNTER DATA>>>>> false condition",
                  countDownStageNumber
                );

                dataForCounter = false;
              } else {
                console.log("API RESPONSE OF COUNTER DATA>>>>>", response.data);
                dataForCounter = response.data.success;
              }
            }
            let data = {
              contractInstance: contractInstance,
              signerWallet: address,
              safetiles: safetiles,
              capcites:capcites,
              leadingCapacity:Number(leadingCapacity),
              globalStage: globalStage,
              allPlayerIds: allPlayerIds,
              startGameTime: adminStartTime,
              nftIds: nftIds,
              latestTimestampOfUsers: latestTimestampOfUsers,
              // latestTimestampOfUsers,
              wrappedEtherInstance: tokenInstance,
              gameInitializeDay: gameInitializeDay,
              isGameEnd: isGameEnd,
              tilesNumber: tilesNumber,
              gameEndTime: gameEndTime,
              ownerAddress: ownerAddress,
              totalReward: totalReward / 10 ** 18,
              countDownStageNumber:countDownStageNumber,
              gameEndDays: Number(gameEnded),
              buyBackCurve:buyBackCurve
            };
            dispatch({
              type: CONTRACT_INSTANCE,
              payload: data,
            });
            let promises = [];
            console.log(
              "ADMIN START TIME>>",
              adminStartTime,
              currentTime,
              lastJumpTime
            );
            for (let i = 0; i <= globalStage; i++) {
              promises.push(await contractInstance.getStagesData(i));
            }
            Promise.all(promises).then((x) => {
              if (x.length > 0) {
                let formattedData = [];
                x.forEach((item, index) => {
                  formattedData.push({ stageNumber: index, stageData: item });
                });

                dispatch({
                  type: STAGES_DATA,
                  payload: formattedData,
                });
              } else {
                dispatch({
                  type: STAGES_DATA,
                  payload: [],
                });
              }
            });
          }
          dispatch({
            type: TOKEN_BALANCE,
            payload: balance,
          });
          dispatch({
            type: LOADER_STATE_FALSE,
          });
          if (dataForCounter == true) {
            dispatch({
              type: COUNTDOWN_LOADER_TRUE,
              payload: true,
            });
          } else {
            dispatch({
              type: COUNTDOWN_LOADER_FALSE,
              payload: false,
            });
          }
        } catch (error) {
          console.log("ADMIN START TIME>>", error);
          dispatch({
            type: FAILING_CONNECTION,
            payload: error,
          });
          dispatch({
            type: LOADER_STATE_FALSE,
          });
          if (error.code === 4001) {
            toast.error("Error while connecting");
          } else if (error.code === -32002) {
            toast.error(error.message);
          } else {
            toast.error(error.message);
          }
        }
      } else {
        toast.error("Please Connect to Avalanche first");
      }
    }
  } else {
    alert("Metamask not installed");
  }
};


export const buyBackCurvess = async (contractInstance) => {

    let buyBackCurves = [];
    for (let i = 0; i < 11; i++) {
        buyBackCurves.push(Number(await contractInstance.buyBackCurve(i) / 10 ** 18));
    }

    return buyBackCurves;
}

export const getLatestData = (contractInstance) => async (dispatch) => {
  let gameStatusGlobalData = await contractInstance.gameStatusInitialized(1);
  let globalStage = Number(gameStatusGlobalData.stageNumber);
  const totalReward = await contractInstance.treasuryBalance();
  let promises = [];
  dispatch({
    type: GLOBAL_STAGE,
    payload: globalStage,
  });
  for (let i = 0; i <= globalStage; i++) {
    promises.push(await contractInstance.getStagesData(i));
  }
  Promise.all(promises).then((x) => {
    if (x.length > 0) {
      let formattedData = [];
      x.forEach((item, index) => {
        formattedData.push({ stageNumber: index, stageData: item });
      });
      dispatch({
        type: STAGES_DATA,
        payload: formattedData,
      });
      dispatch({
        type: TRESUARY_BALANCE,
        payload: totalReward / 10 ** 18,
      });
    } else {
      dispatch({
        type: STAGES_DATA,
        payload: [],
      });
    }
  });
};

export const CheckMetaMaskInstalled = () => async (dispatch) => {
  window.addEventListener("load", () => {
    try {
      if (typeof window.ethereum !== "undefined") {
        dispatch({
          type: IS_INSTALLED,
          payload: true,
        });
      } else {
        dispatch({
          type: IS_NOT_INSTALLED,
          payload: false,
        });
      }
    } catch (error) {
      toast.error("error in metamask connection");
    }
  });
};

export const accountsCheck = () => async (dispatch) => {
  window.addEventListener("load", () => {
    try {
      // if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on("accountsChanged", (accounts) => {
        let data = {};
        if (accounts.length > 0) {
          data.account = accounts;
          data.length = accounts.length;
          dispatch({
            type: ACCOUNTS_CHANGE,
            payload: data,
          });
        }
      });
      // }
    } catch (error) {
      toast.error("error in accounts changing in metamask");
    }
  });
};

const computePlayerIDPromise = async (nftsObject, address) => {
  try {
    nftsObject.map(async (data) => {
      const playerId = generateId(data.tokenId, data.series, address);
      const ipfsAddress =
        process.env.REACT_APP_IPFS_URL + data.ipfsAddress.replace(":/", "");
      data.ipfsAddress = ipfsAddress;
      if (data.series == "seriesTwo") {
        data.image = data.metaData.ipfsImage;
      }
      if (data.series == "seriesOne") {
        data.image =
          process.env.REACT_APP_IPFS_URL +
          data.metaData.ipfsImage.replace(":/", "");
      }
      data.name = data.metaData.name;
      data.playerId = playerId;
    });
    return nftsObject;
  } catch (e) {
    console.log("ERROR>>>>", e);
  }
};

const generateId = (nftId, seriesType, address) => {
  if (typeof window.ethereum !== "undefined") {
    let series;
    if (seriesType == "seriesOne") {
      series = 1;
    }
    if (seriesType == "seriesTwo") {
      series = 2;
    }
    const bufferArray = ethers.utils.solidityPack(
      ["address", "uint256", "uint8"],
      [address, nftId, series]
    );
    const messageHashBytes = ethers.utils.concat([bufferArray]);
    const playerID = ethers.utils.keccak256(messageHashBytes);
    return playerID;
  }
};

export const getLatestStages = (contractInstance) => async (dispatch) => {
  let gameStatusGlobalData = await contractInstance.gameStatusInitialized(1);
  let globalStage = Number(gameStatusGlobalData.stageNumber);
  let isGameEnd = await contractInstance.isGameEnded();
  const data = await contractInstance.getAll();
  let randomNumber =
    data[globalStage !== 0 ? globalStage - 1 : globalStage]?._hex;
  const tilesNumber =
    globalStage < 1
      ? 1
      : isGameEnd == false
      ? globalStage
      : randomNumber !== undefined
      ? globalStage + 1
      : globalStage;
  dispatch({
    type: SET_LATEST_STAGES,
    payload: tilesNumber,
  });
};

export const closeModal = () => async (dispatch) => {
  dispatch({
    type: COUNTDOWN_LOADER_FALSE,
    payload: false,
  });
};
